import React from 'react';
import { Link } from 'react-router-dom';
import styles from './CGU.module.css';

const CGU = () => {
  return (
    <div className={styles['cgu-container']}>
      <h1>Conditions Générales d'Utilisation</h1>
      <p className={styles['update-date']}>Dernière mise à jour : 19/07/2024</p>
      
      <h2>1. Propriété intellectuelle</h2>
      <p>Tout le contenu présent sur ce site, incluant mais non limité aux textes, graphiques, logos, images, clips audio, vidéos, et logiciels, est la propriété exclusive de l'auteur de ce site et est protégé par les lois sur le droit d'auteur et autres lois sur la propriété intellectuelle.</p>
      
      <h2>2. Utilisation du contenu</h2>
      <p>L'utilisation non autorisée du contenu de ce site est strictement interdite. Aucune partie du contenu ne peut être reproduite, distribuée, ou transmise sous quelque forme ou par quelque moyen que ce soit, sans l'autorisation écrite préalable du propriétaire du site.</p>
      
      <h2>3. Limitation de responsabilité</h2>
      <p>Les informations fournies sur ce site le sont à titre informatif uniquement. Le propriétaire du site ne garantit pas l'exactitude, l'exhaustivité ou l'actualité des informations fournies.</p>
      
      <h2>4. Modifications des CGU</h2>
      <p>Le propriétaire du site se réserve le droit de modifier ces CGU à tout moment. Les utilisateurs sont encouragés à vérifier régulièrement cette page pour prendre connaissance des changements éventuels.</p>
      
      <p className={styles['contact-info']}>Pour toute question concernant ces CGU, veuillez contacter : cremi532@gmail.com</p>
      
      <Link to="/" className={styles['back-link']}>Retour à l'accueil</Link>
    </div>
  );
};

export default CGU;