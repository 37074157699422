import React from "react";
import styles from "./MathDisplay.module.css";

const Matrix = ({ matrix, isTransposed = false }) => {
  return (
    <div className={styles.matrixWrapper}>
      {isTransposed &&
      <span style={{ marginLeft: "5px" }}>
        {" T "}
        </span>}

      <div className={styles.matrix}>
        {matrix.map((row, rowIndex) => (
          <div key={rowIndex} className={styles.matrixRow}>
            {row.map((cell, cellIndex) => (
              <span key={cellIndex} className={styles.matrixCell}>
                {cell}
              </span>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

const MathDisplay = ({ children }) => (
  <React.Fragment>
    <div className={styles.mathDisplay}>{children}</div>
  </React.Fragment>
);

const Space = () => <span style={{ margin: "0 5px" }}></span>;

const Fraction = ({ numerator, denominator }) => (
  <div className={styles.fraction}>
    <span className={styles.numerator}>{numerator}</span>
    <span className={styles.denominator}>{denominator}</span>
  </div>
);

const Equation = ({ left, right }) => (
  <div className={styles.equation}>
    <span className={styles.left}>{left}</span>
    <span className={styles.equals}>=</span>
    <span className={styles.right}>{right}</span>
  </div>
);

const Parentheses = ({ children }) => (
  <div className={styles.parenthesesWrapper}>
    <div className={styles.parenthesis + " " + styles.leftParenthesis}></div>
    <div className={styles.parenthesesContent}>{children}</div>
    <div className={styles.parenthesis + " " + styles.rightParenthesis}></div>
  </div>
);

const Operator = ({ children }) => (
  <span className={styles.operator}>{children}</span>
);

const Division = ({ children }) => (
  <div className={styles.division}>{children}</div>
);

export {
  Matrix,
  MathDisplay,
  Fraction,
  Equation,
  Parentheses,
  Operator,
  Division,
  Space
};