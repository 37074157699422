import React from "react";
import { motion } from "framer-motion";
import {
    Matrix,
    MathDisplay,
    Equation,
    Parentheses,
    Operator,
    Space,
  } from "libs/matrix/Matrix";
  
const Backpropagation = () => {
  return (
    <div className="backpropagation">
    <h2>Rétropropagation</h2>
    <div className="layer">
      <h3>1. Gradient de sortie</h3>
      <p>
        Le gradient de sortie est la première étape de la
        rétropropagation. Il mesure comment l'erreur change par
        rapport aux sorties du réseau.
      </p>

      <p>
        Nous commençons par calculer la dérivée de l'erreur
        quadratique moyenne (MSE) par rapport à la sortie du réseau :
      </p>
      <MathDisplay>
        <Equation left={<>∂MSE / ∂y</>} right={<>(2/n)(y - ŷ)</>} />
      </MathDisplay>

      <p>Où :</p>
      <ul>
        <li>
          n est le nombre de neurones de sortie (dans notre cas, n =
          2)
        </li>
        <li>y est la sortie prédite par le réseau</li>
        <li>ŷ est la sortie attendue (ou cible)</li>
      </ul>

      <p>Appliquons maintenant cette formule à notre cas :</p>
      <MathDisplay>
        <Equation
          left="Gradient de sortie"
          right="(1/2)((3.66 - 1), (4.93 - 0))"
        />
      </MathDisplay>

      <p>Détaillons le calcul étape par étape :</p>
      <MathDisplay>
        <Operator>2 ×</Operator>
        <Parentheses>
          <Matrix matrix={[[3.66], [4.93]]} />
          <Operator>-</Operator>
          <Matrix matrix={[[1], [0]]} />
        </Parentheses>
        <Operator> / 2</Operator>
      </MathDisplay>

      <p>
        1. Nous soustrayons d'abord les valeurs cibles des sorties
        prédites :
      </p>
      <MathDisplay>
        <Equation
          left=""
          right={<Matrix matrix={[[3.66 - 1], [4.93 - 0]]} />}
        />
      </MathDisplay>

      <p>
        2. Ensuite, nous multiplions par 2 (le numérateur de 2/n) :
      </p>
      <MathDisplay>
        <Equation
          left=""
          right={
            <Matrix matrix={[[2 * (3.66 - 1)], [2 * (4.93 - 0)]]} />
          }
        />
      </MathDisplay>

      <p>
        3. Enfin, nous divisons par n (qui est 2 dans notre cas) :
      </p>
      <MathDisplay>
        <Equation
          left=""
          right={<Matrix matrix={[[5.32 / 2], [9.86 / 2]]} />}
        />
      </MathDisplay>

      <p>Ce qui nous donne le résultat final :</p>
      <MathDisplay>
        <Equation
          left="Gradient de sortie"
          right={<Matrix matrix={[[2.66], [4.93]]} />}
        />
      </MathDisplay>

      <p>
        Ce gradient de sortie nous indique dans quelle direction et
        avec quelle intensité nous devons ajuster les poids de la
        dernière couche pour réduire l'erreur. Une valeur positive
        signifie que la sortie était trop élevée, tandis qu'une valeur
        négative signifierait qu'elle était trop basse.
      </p>
    </div>
    <div className="layer">
      <h3>2. Dense 2 (rétropropagation)</h3>

      <p>
        La rétropropagation de la couche Dense 2 est une étape
        cruciale pour ajuster les poids du réseau. Nous allons
        calculer le gradient des poids et mettre à jour ces poids en
        conséquence.
      </p>

      <h4>Calcul du gradient des poids</h4>
      <p>
        Le gradient des poids (WG) est calculé en multipliant la
        sortie de la couche précédente (ReLU output) par la transposée
        du gradient de sortie :
      </p>
      <MathDisplay>
        <Equation
          left="WG"
          right="ReLU output × (Gradient de sortie)T"
        />
      </MathDisplay>

      <p>Appliquons cette formule avec nos valeurs :</p>
      <MathDisplay>
        <Matrix matrix={[[2.66], [4.93]]} />
        {" × "}
        <Matrix isTransposed={true} matrix={[[0.6], [1.3], [2.0]]} />
      </MathDisplay>

      <p>Ce qui donne :</p>
      <MathDisplay>
        <Equation
          left="WG"
          right={
            <>
              <MathDisplay>
                <Matrix matrix={[[2.66], [4.93]]} />
                {" × "}
                <Matrix matrix={[[0.6, 1.3, 2.0]]} />
              </MathDisplay>
            </>
          }
        />
      </MathDisplay>

      <p>
        Après calcul, nous obtenons le résultat final pour le gradient
        des poids :
      </p>
      <MathDisplay>
        <Equation
          left="WG"
          right={
            <Matrix
              matrix={[
                [1.5, 3.45, 5.32],
                [2.9, 6.4, 9.86],
              ]}
            />
          }
        />
      </MathDisplay>

      <h4>Mise à jour des poids</h4>
      <p>
        Maintenant que nous avons le gradient des poids, nous pouvons
        mettre à jour les poids de la couche Dense 2. La formule pour
        calculer les nouveaux poids est :
      </p>
      <MathDisplay>
        <Equation left="W2_new" right="T(W2) - WG * 0.001" />
      </MathDisplay>

      <p>
        Où 0.001 est le taux d'apprentissage, un hyperparamètre qui
        contrôle la vitesse d'ajustement des poids.
      </p>

      <p>Appliquons cette formule avec nos valeurs :</p>
      <MathDisplay>
        <Matrix
          isTransposed={true}
          matrix={[
            [0.7, 1.0],
            [0.8, 1.1],
            [0.9, 1.2],
          ]}
        />
        <Space />
        {" - "}
        <Space />
        <Matrix
          matrix={[
            [1.5, 3.45, 5.32],
            [2.9, 6.4, 9.86],
          ]}
        />
        <Space />
        {" × 0.001 "}
      </MathDisplay>

      <p>Ce qui équivaut à :</p>
      <MathDisplay>
        <Matrix
          matrix={[
            [0.7, 0.8, 0.9],
            [1.0, 1.1, 1.2],
          ]}
        />
        <Space />
        {" - "}
        <Space />
        <Matrix
          matrix={[
            [1.5, 3.45, 5.32],
            [2.9, 6.4, 9.86],
          ]}
        />
        <Space />
        {" × 0.001 "}
      </MathDisplay>

      <p>
        Après calcul, nous obtenons les nouveaux poids pour la couche
        Dense 2 :
      </p>
      <Equation
        left="nouveau poids W2 "
        right={
          <Matrix
            matrix={[
              [0.589, 0.697, 0.795],
              [0.897, 0.994, 1.09],
            ]}
          />
        }
      />

      <h4>Calcul du gradient d'entrée pour ReLU</h4>
      <p>
        La dernière étape de cette phase est le calcul du gradient
        d'entrée (IG) pour la couche ReLU précédente. Ce gradient sera
        utilisé pour propager l'erreur vers les couches antérieures.
      </p>

      <p>
        Pour calculer ce gradient, on multiplie la transposée des
        poids de la couche Dense 2 par le gradient de sortie :
      </p>
      <MathDisplay>
        <Equation left="IG" right="T(W2) × Gradient de sortie" />
      </MathDisplay>

      <p>Appliquons cette formule avec nos valeurs :</p>
      <MathDisplay>
        {"="}
        <Matrix
          isTransposed={true}
          matrix={[
            [0.587, 0.897],
            [0.697, 0.994],
            [0.795, 1.09],
          ]}
        />
        {" × "}
        <Matrix matrix={[[2.66], [4.93]]} />
      </MathDisplay>

      <p>Ce qui donne :</p>
      <Equation
        left="IG"
        right={
          <MathDisplay>
            <Matrix
              matrix={[
                [0.589, 0.697, 0.795],
                [0.897, 0.994, 1.09],
              ]}
            />
            {" × "}
            <Matrix matrix={[[2.66], [4.93]]} />
          </MathDisplay>
        }
      />

      <p>
        Après calcul, nous obtenons le résultat final pour le gradient
        d'entrée :
      </p>
      <Equation
        left="IG"
        right={<Matrix matrix={[[5.989], [6.751], [7.488]]} />}
      />

      <p>
        Ce gradient d'entrée sera utilisé dans la prochaine étape pour
        mettre à jour les poids de la couche précédente (Dense 1),
        permettant ainsi à l'erreur de se propager en arrière à
        travers le réseau.
      </p>
    </div>
    <div className="layer">
      <h3>3. Rétropropagation de la couche ReLU</h3>
      <p>
        Les couches d'activation ReLU ne possèdent pas de paramètres
        (de poids) à modifier, donc on passe directement au calcul du
        gradient de sortie pour la couche Dense 1.
      </p>
      <p>
        Pour cela, on multiplie le gradient d'entrée par la dérivée de
        la fonction ReLU (1 si x {">"} 0, 0 sinon) : Donc dans notre
        cas la matrice de dérivée donnera que des 1
      </p>
      <Equation
        left="IG"
        right={
          <MathDisplay>
            <Matrix matrix={[[5.989], [6.751], [7.488]]} />
            {" × "}
            <Matrix matrix={[[1], [1], [1]]} />
          </MathDisplay>
        }
      />
      <MathDisplay>
        <Equation
          left="IG"
          right={<Matrix matrix={[[5.989], [6.751], [7.488]]} />}
        />
      </MathDisplay>
    </div>

    <div className="layer">
      <h3>4. Rétropropagation de la couche Dense 1</h3>
      <p>
        Les étapes sont les mêmes que pour la couche Dense 2, mais
        avec les poids et les valeurs de la couche Dense 1 et le
        gradient de sortie de la couche ReLU. Donc toujours la même
        formule (Input de la couche Dense N) x T(Gradient de sortie de
        la couche N+1)
      </p>
      <Equation
        left="WG"
        right={
          <MathDisplay>
            <Matrix matrix={[[5.989], [6.751], [7.488]]} />
            {" × "}
            <Matrix isTransposed={true} matrix={[[1], [2]]} />
          </MathDisplay>
        }
      />
      <MathDisplay>
        <Equation
          left="WG"
          right={
            <Matrix
              matrix={[
                [5.989, 11.978],
                [6.751, 13.502],
                [7.488, 14.976],
              ]}
            />
          }
        />
      </MathDisplay>
      <p>Calcul des nouveaux poids :</p>
      <p>
        La formule est la même que pour la couche Dense 2 : poids =
        poids - (T(poids) - WG * 0.001)
      </p>
      <Equation left="W1_new" right="T(W1) - WG * 0.001" />
      <Equation
        left="W1_new"
        right={
          <MathDisplay>
            <Matrix
              isTransposed={true}
              matrix={[
                [0.1, 0.3, 0.5],
                [0.2, 0.4, 0.6],
              ]}
            />
            <Space />
            {" - "}
            <Space />
            <Matrix
              matrix={[
                [5.989, 11.978],
                [6.751, 13.502],
                [7.488, 14.976],
              ]}
            />
            <Space />
            {" × 0.001"}
          </MathDisplay>
        }
      />
      <MathDisplay>
        <Equation
          left="W1_new"
          right={
            <Matrix
              matrix={[
                [0.094, 0.188],
                [0.293, 0.386],
                [0.493, 0.585],
              ]}
            />
          }
        />
      </MathDisplay>
    </div>

    <div className="layer">
      <h3>5. Conclusion</h3>
      <p>
        Les poids ont été mis à jour pour les deux couches Dense, la
        rétropropagation est terminée.
      </p>
      <p>
        Il faut maintenant répéter le processus X fois avec des
        valeurs différentes (input, output) pour améliorer le modèle.
      </p>

      <p>
        Dans cette explication, nous n'avons pas abordé les biais,
        mais ils sont mis à jour de la même manière que les poids.
      </p>
      <p>
        Enfin, nous n'avons pas vu la normalisation des données, qui
        est une étape importante pour améliorer la convergence du
        modèle. Il y a aussi d'autres fonctions d'activation que la
        ReLU, comme la sigmoïde ou la tangente hyperbolique, qui
        peuvent être utilisées.
      </p>
      <motion.a
        href="https://github.com/Azzary/LeafNeural-Network"
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        className="github-link"
      >
        Implémentation d'un réseau de neurones avec numpy
      </motion.a>
    </div>
  </div>
  );
};

export default Backpropagation;