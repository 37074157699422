import React from "react";
import "./PageContainer.css";

const PageContainer = ({ children }) => {
  return (
    <div className="page__container">
      {children}
    </div>
  );
};

export default PageContainer;