import React from 'react';
import { Route, Routes } from 'react-router-dom';
import NeuralNetworkExplanation from "./nn-fundamentals/NeuralNetworkExplanation";
import HomePage from "./home/HomePage";
import CGU from "./CGU/CGU";
import BookManagement from "./book-management/BookManagement";

const AppRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/nn-fundamentals" element={<NeuralNetworkExplanation />} />
      <Route path="/cgu" element={<CGU />} />
      {/* <Route path="/books" element={<BookManagement />} /> */}
    </Routes>
  );
};

export default AppRouter;