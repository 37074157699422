import React from "react";
import {
  MathDisplay,
  Fraction,
  Equation,
} from "libs/matrix/Matrix"; 


const ErrorCalculation = () => {
  return (
    <div className="error-calculation">
      <h2>Calcul de l'Erreur</h2>
      <div className="layer error-details">
        <h3>Pourquoi calculer l'erreur ?</h3>
        <p>
          Le calcul de l'erreur est une étape cruciale dans l'apprentissage d'un
          réseau de neurones. Il nous permet de quantifier à quel point les
          prédictions du réseau sont éloignées des valeurs réelles attendues.
          Cette mesure guide ensuite le processus d'ajustement des poids du
          réseau lors de la rétropropagation.
        </p>

        <h3>Données de départ</h3>
        <p>Valeurs attendues (cibles) : [1, 0]</p>
        <p>Valeurs obtenues (prédictions du réseau) : [3.66, 4.93]</p>

        <h3>Formule de l'erreur quadratique moyenne (MSE)</h3>
        <p>
          Nous utilisons l'erreur quadratique moyenne, qui est définie comme
          suit :
        </p>
        <MathDisplay>
          <Equation
            left="MSE"
            right={
              <Fraction
                numerator={<>Σ(y_pred - y_true)²</>}
                denominator="nb neurones"
              />
            }
          />
        </MathDisplay>
        <p>
          où y_pred sont les valeurs prédites et y_true sont les valeurs cibles.
        </p>

        <h3>Calcul détaillé</h3>
        <p>
          1. Calculons d'abord les différences au carré pour chaque neurone de
          sortie :
        </p>
        <MathDisplay>
          <Equation
            left="Neurone 1"
            right={<>(3.66 - 1)² = 2.66² = 7.0756</>}
          />
        </MathDisplay>
        <MathDisplay>
          <Equation
            left="Neurone 2"
            right={<>(4.93 - 0)² = 4.93² = 24.3049</>}
          />
        </MathDisplay>

        <p>2. Ensuite, nous faisons la somme de ces différences :</p>
        <MathDisplay>
          <Equation left="Somme" right={<>7.0756 + 24.3049 = 31.3805</>} />
        </MathDisplay>

        <p>
          3. Enfin, nous divisons par le nombre de neurones de sortie (2 dans
          notre cas) :
        </p>
        <MathDisplay>
          <Equation
            left="Erreur (MSE)"
            right={<Fraction numerator={<>31.3805</>} denominator={2} />}
          />
        </MathDisplay>

        <p>Résultat final :</p>
        <MathDisplay>
          <Equation left="Erreur (MSE)" right="15.69025" />
        </MathDisplay>

        <h3>Interprétation du résultat</h3>
        <p>
          Cette valeur d'erreur de 15.69025 représente la moyenne des écarts au
          carré entre les prédictions et les valeurs cibles. Plus cette valeur
          est proche de zéro, meilleures sont les prédictions du réseau.
        </p>
        <p>
          Dans notre cas, l'erreur est assez élevée, ce qui indique que notre
          réseau a encore beaucoup à apprendre. Les prédictions (3.66 et 4.93)
          sont très éloignées des valeurs cibles (1 et 0).
        </p>

        <h3>Utilisation de cette erreur</h3>
        <p>
          Cette valeur d'erreur sera utilisée dans l'étape de rétropropagation
          pour ajuster les poids du réseau. L'objectif de l'apprentissage est de
          minimiser cette erreur au fil des itérations, en modifiant
          progressivement les poids pour que les prédictions se rapprochent des
          valeurs cibles.
        </p>
        <p>
          La rétropropagation utilisera la dérivée de cette fonction d'erreur
          par rapport aux poids du réseau pour déterminer comment les ajuster de
          manière optimale.
        </p>
      </div>
    </div>
  );
};

export default ErrorCalculation;
