import React from "react";
import { motion } from "framer-motion";
import NeuralNetwork from 'components/NeuralNetwork';


const Structure = () => {
  return (
    <div className="network-structure">
      <h2>Structure du Réseau</h2>

      <div className="layer">
        <h3>Vue d'ensemble</h3>
        <p className="network-overview">
          Input (2 neurones) → Dense 1 (3 neurones) → ReLU → Dense 2 (2
          neurones) → Output
        </p>
        <NeuralNetwork />
      </div>

      <div className="layer">
        <h3>Détails des couches</h3>

        <div className="layer-details">
          <h4>1. Couche d'entrée</h4>
          <p>
            Nombre de neurones : 2
            <br />
            Rôle : Reçoit les données brutes d'entrée. Chaque neurone
            représente une caractéristique de nos données.
          </p>
        </div>

        <div className="layer-details">
          <h4>2. Couche Dense 1 (Première couche cachée)</h4>
          <p>
            Nombre de neurones : 3
            <br />
            Type : Entièrement connectée (Dense)
            <br />
            Rôle : Extrait des caractéristiques de bas niveau à partir
            des entrées. Chaque neurone est connecté à tous les neurones
            de la couche précédente.
          </p>
        </div>

        <div className="layer-details">
          <h4>3. Fonction d'activation ReLU</h4>
          <p>
            Type : Rectified Linear Unit
            <br />
            Formule : f(x) = max(0, x)
            <br />
            Rôle : Introduit une non-linéarité dans le réseau,
            permettant d'apprendre des relations complexes. Elle aide à
            résoudre le problème de disparition du gradient.
          </p>
        </div>

        <div className="layer-details">
          <h4>4. Couche Dense 2 (Couche de sortie)</h4>
          <p>
            Nombre de neurones : 2
            <br />
            Type : Entièrement connectée (Dense)
            <br />
            Rôle : Produit la prédiction finale du réseau. Le nombre de
            neurones correspond au nombre de classes ou de valeurs à
            prédire.
          </p>
        </div>
      </div>

      <div className="layer">
        <h3>Pourquoi cette structure ?</h3>
        <p>
          Cette architecture simple est efficace pour de nombreuses
          tâches de classification et de régression. La couche cachée
          permet au réseau d'apprendre des représentations
          intermédiaires des données, tandis que ReLU introduit la
          non-linéarité nécessaire pour modéliser des relations
          complexes.
        </p>
      </div>

      <div className="github-link-container">
        <motion.a
          href="https://github.com/Azzary/LeafNeural-Network"
          className="github-link"
          target="_blank"
          rel="noopener noreferrer"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          <svg
            height="32"
            aria-hidden="true"
            viewBox="0 0 16 16"
            version="1.1"
            width="32"
            data-view-component="true"
            className="github-icon"
          >
            <path
              fillRule="evenodd"
              d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.013 8.013 0 0016 8c0-4.42-3.58-8-8-8z"
            ></path>
          </svg>
          <span className="github-text">
            Explorez l'implémentation du réseau neuronal
          </span>
        </motion.a>
      </div>
    </div>
  );
};

export default Structure;