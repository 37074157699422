import React from 'react';

const NebulaIcon = ({ isVisible, color = '#6C63FF', size = 40 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      width={size}
      height={size}
    >
      <defs>
        <linearGradient id="nebulaGradient" x1="0%" y1="0%" x2="100%" y2="100%">
          <stop offset="0%" stopColor={color} stopOpacity="0.2" />
          <stop offset="50%" stopColor={color} stopOpacity="0.7" />
          <stop offset="100%" stopColor={color} stopOpacity="0.2" />
        </linearGradient>
        <filter id="nebulaGlow">
          <feGaussianBlur stdDeviation="2" result="glow" />
          <feMerge>
            <feMergeNode in="glow" />
            <feMergeNode in="glow" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>
      
      {isVisible ? (
        // Visible Nebula
        <g filter="url(#nebulaGlow)">
          <path
            d="M10,50 Q25,25 50,50 T90,50 Q75,75 50,50 T10,50"
            fill="url(#nebulaGradient)"
            stroke={color}
            strokeWidth="2"
          />
          <circle cx="30" cy="30" r="3" fill={color} />
          <circle cx="70" cy="70" r="3" fill={color} />
          <circle cx="50" cy="50" r="4" fill={color} />
        </g>
      ) : (
        // Hidden Nebula
        <g>
          <circle cx="50" cy="50" r="35" fill="none" stroke={color} strokeWidth="2" strokeDasharray="5,5" />
          <path
            d="M35,50 Q50,35 65,50 T35,50"
            fill="none"
            stroke={color}
            strokeWidth="2"
          />
          <circle cx="35" cy="50" r="3" fill={color} />
          <circle cx="65" cy="50" r="3" fill={color} />
          <circle cx="50" cy="50" r="4" fill={color} />
        </g>
      )}
    </svg>
  );
};

export default NebulaIcon;