import React from "react";
import {
  Matrix,
  MathDisplay,
  Equation,
  Space,
} from "libs/matrix/Matrix";


const ForwardPropagation = () => {
  return (
    <div className="forward-propagation">
    <h2>Propagation Avant</h2>

    <div className="layer">
      <h3>Qu'est-ce que la propagation avant ?</h3>
      <p>
        La propagation avant est le processus par lequel les données
        d'entrée traversent le réseau neuronal de gauche à droite,
        couche par couche, jusqu'à produire une sortie. C'est pendant
        cette phase que le réseau fait ses prédictions.
      </p>
    </div>

    <div className="layer">
      <h3>1. Couche d'entrée</h3>
      <p>
        La couche d'entrée reçoit les données brutes. Dans notre cas,
        nous avons deux neurones d'entrée.
      </p>
      <MathDisplay>
        <Equation
          left="Input"
          right={<Matrix matrix={[[1], [2]]} />}
        />
      </MathDisplay>
      <p>
        Ces valeurs (1 et 2) représentent les caractéristiques de
        notre exemple d'entrée.
      </p>
    </div>

    <div className="layer">
      <h3>2. Dense 1 (Première couche cachée)</h3>
      <p>
        Cette couche est appelée "dense" car chaque neurone est
        connecté à tous les neurones de la couche précédente.
      </p>

      <h4>Poids de la couche :</h4>
      <MathDisplay>
        <Equation
          left="W1"
          right={
            <Matrix
              matrix={[
                [0.1, 0.3, 0.5],
                [0.2, 0.4, 0.6],
              ]}
            />
          }
        />
      </MathDisplay>
      <p>
        Chaque colonne représente les poids pour un neurone de cette
        couche.
      </p>

      <h4>Biais de la couche :</h4>
      <p>Biais : [0.1, 0.2, 0.3]</p>
      <p>
        Le biais permet au réseau d'ajuster le seuil d'activation de
        chaque neurone.
      </p>

      <h4>Calcul de la sortie de la couche :</h4>
      <MathDisplay>
        <Equation left="Output" right="Input × W1 + Biais" />
      </MathDisplay>

      <p>En détail, cela donne :</p>
      <MathDisplay>
        <Matrix matrix={[[1, 2]]} />
        {" × "}
        <Matrix
          matrix={[
            [0.1, 0.3, 0.5],
            [0.2, 0.4, 0.6],
          ]}
        />
        {" + "}
        <Matrix matrix={[[0.1, 0.2, 0.3]]} />
      </MathDisplay>

      <p>Étapes détaillées du calcul :</p>
      <ol>
        <li>
          Multiplication matricielle :
          <MathDisplay>
            <Equation
              left="(1 × 0.1 + 2 × 0.2, 1 × 0.3 + 2 × 0.4, 1 × 0.5 + 2 × 0.6)"
              right="(0.5, 1.1, 1.7)"
            />
          </MathDisplay>
        </li>
        <li>
          Addition du biais :
          <MathDisplay>
            <Equation
              left="(0.5, 1.1, 1.7) + (0.1, 0.2, 0.3)"
              right="(0.6, 1.3, 2.0)"
            />
          </MathDisplay>
        </li>
      </ol>

      <p>Résultat final de la couche Dense 1 :</p>
      <MathDisplay>
        <Equation
          left="Output"
          right={<Matrix matrix={[[0.6], [1.3], [2.0]]} />}
        />
      </MathDisplay>
      <p>
        Ces valeurs représentent l'activation des trois neurones de
        cette couche cachée.
      </p>
    </div>

    <div className="layer">
      <h3>3. ReLU (Fonction d'activation)</h3>
      <p>
        ReLU (Rectified Linear Unit) est une fonction d'activation non
        linéaire. Elle est définie comme f(x) = max(0, x).
      </p>
      <p>
        Dans notre cas, toutes les valeurs sont positives, donc ReLU
        ne change rien :
      </p>
      <MathDisplay>
        <Equation
          left="ReLU output"
          right={<Matrix matrix={[[0.6], [1.3], [2.0]]} />}
        />
      </MathDisplay>
      <p>
        L'importance de ReLU est d'introduire une non-linéarité dans
        le réseau, permettant d'apprendre des relations complexes.
      </p>
    </div>

    <div className="layer">
      <h3>4. Dense 2 (Couche de sortie)</h3>
      <p>
        C'est notre couche de sortie, qui produit la prédiction finale
        du réseau.
      </p>

      <h4>Poids de la couche :</h4>
      <MathDisplay>
        <Equation
          left="W2"
          right={
            <Matrix
              matrix={[
                [0.7, 1.0],
                [0.8, 1.1],
                [0.9, 1.2],
              ]}
            />
          }
        />
      </MathDisplay>

      <h4>Biais de la couche :</h4>
      <p>Biais : [0.4, 0.5]</p>

      <h4>Calcul de la sortie finale :</h4>
      <MathDisplay>
        <Equation left="Output" right="T(ReLU output) × W2 + Biais" />
      </MathDisplay>

      <p>En détail :</p>
      <MathDisplay>
        <Matrix matrix={[[0.6, 1.3, 2.0]]} />
        <Space />
        {" × "}
        <Space />
        <Matrix
          matrix={[
            [0.7, 1.0],
            [0.8, 1.1],
            [0.9, 1.2],
          ]}
        />
        <Space />
        {" + "}
        <Space />
        <Matrix matrix={[[0.4, 0.5]]} />
      </MathDisplay>

      <p>Étapes détaillées :</p>
      <ol>
        <li>
          Multiplication matricielle :
          <MathDisplay>
            <Equation
              left="(0.6 × 0.7 + 1.3 × 0.8 + 2.0 × 0.9, 0.6 × 1.0 + 1.3 × 1.1 + 2.0 × 1.2)"
              right="(3.26, 4.43)"
            />
          </MathDisplay>
        </li>
        <li>
          Addition du biais :
          <MathDisplay>
            <Equation
              left="(3.26, 4.43) + (0.4, 0.5)"
              right="(3.66, 4.93)"
            />
          </MathDisplay>
        </li>
      </ol>

      <p>Résultat final (prédiction du réseau) :</p>
      <MathDisplay>
        <Equation
          left="Output"
          right={<Matrix matrix={[[3.66], [4.93]]} />}
        />
      </MathDisplay>
    </div>

    <div className="layer">
      <h3>Conclusion sur la propagation avant</h3>
      <p>
        La propagation avant transforme nos données d'entrée [1, 2] en
        une prédiction finale [3.66, 4.93]. Cette prédiction sera
        comparée aux valeurs réelles attendues pour calculer l'erreur
        du réseau, qui sera ensuite utilisée pour ajuster les poids
        lors de la rétropropagation.
      </p>
      <p>
        Chaque couche du réseau apprend à extraire et à combiner des
        caractéristiques de plus en plus abstraites des données
        d'entrée, permettant au réseau de modéliser des relations
        complexes.
      </p>
    </div>
  </div>
  );
};

export default ForwardPropagation;