import React, { useState, useEffect, useRef, useCallback } from "react";
import * as THREE from "three";
import {
  EffectComposer,
  RenderPass,
  EffectPass,
  BloomEffect,
} from "postprocessing";
import NebulaIcon from "./NebulaIcon";

const NebulaBackground = () => {
  const mountRef = useRef(null);
  const [showNebula, setShowNebula] = useState(true);
  const sceneRef = useRef();
  const cameraRef = useRef();
  const rendererRef = useRef();
  const composerRef = useRef();
  const cloudParticlesRef = useRef([]);
  const frameId = useRef(null);
  const isInitializedRef = useRef(false);

  const animate = useCallback(() => {
    if (showNebula && composerRef.current) {
      cloudParticlesRef.current.forEach((p) => {
        p.rotation.z -= 0.001;
      });
      composerRef.current.render();
    }
    frameId.current = requestAnimationFrame(animate);
  }, [showNebula]);

  const handleResize = useCallback(() => {
    if (!isInitializedRef.current) return;

    const width = window.innerWidth;
    const height = window.innerHeight;

    if (cameraRef.current) {
      cameraRef.current.aspect = width / height;
      cameraRef.current.updateProjectionMatrix();
    }

    if (rendererRef.current) {
      rendererRef.current.setSize(width, height);
    }

    if (composerRef.current) {
      composerRef.current.setSize(width, height);
    }
  }, []);

  useEffect(() => {
    const init = () => {
      sceneRef.current = new THREE.Scene();
      cameraRef.current = new THREE.PerspectiveCamera(
        60,
        window.innerWidth / window.innerHeight,
        1,
        1000
      );
      cameraRef.current.position.z = 1;
      cameraRef.current.rotation.x = 1.16;
      cameraRef.current.rotation.y = -0.12;
      cameraRef.current.rotation.z = 0.1;

      rendererRef.current = new THREE.WebGLRenderer({
        antialias: true,
        alpha: true, // This allows transparency
      });
      rendererRef.current.setSize(window.innerWidth, window.innerHeight);
      rendererRef.current.setPixelRatio(Math.min(window.devicePixelRatio, 2));
      mountRef.current.appendChild(rendererRef.current.domElement);

      // Set clear color to transparent
      rendererRef.current.setClearColor(0x000000, 0);

      const ambient = new THREE.AmbientLight(0x555555);
      sceneRef.current.add(ambient);

      const directionalLight = new THREE.DirectionalLight(0x0033ff, 0.5);
      directionalLight.position.set(0, 0, 1);
      sceneRef.current.add(directionalLight);

      const blueLight = new THREE.PointLight(0x3677ac, 30, 450, 1.7);
      blueLight.position.set(200, 300, 100);
      sceneRef.current.add(blueLight);
      const redLight = new THREE.PointLight(0x661111, 30, 450, 1.7);
      redLight.position.set(100, 300, 100);
      sceneRef.current.add(redLight);

      const loader = new THREE.TextureLoader();
      loader.load("/smoke.png", (texture) => {
        const cloudGeo = new THREE.PlaneGeometry(500, 500);
        const cloudMaterial = new THREE.MeshLambertMaterial({
          map: texture,
          transparent: true,
        });

        for (let p = 0; p < 20; p++) {
          let cloud = new THREE.Mesh(cloudGeo, cloudMaterial);
          cloud.position.set(
            Math.random() * 800 - 400,
            500,
            Math.random() * 500 - 500
          );
          cloud.rotation.x = 1.16;
          cloud.rotation.y = -0.12;
          cloud.rotation.z = Math.random() * 2 * Math.PI;
          cloud.material.opacity = Math.random() * 0.2 + 0.1;
          cloudParticlesRef.current.push(cloud);
          sceneRef.current.add(cloud);
        }

        const bloomEffect = new BloomEffect({
          kernelSize: 2,
          luminanceThreshold: 0.2,
          luminanceSmoothing: 0.5,
          intensity: 0.7,
        });

        const effectPass = new EffectPass(cameraRef.current, bloomEffect);
        effectPass.renderToScreen = true;

        composerRef.current = new EffectComposer(rendererRef.current);
        composerRef.current.addPass(
          new RenderPass(sceneRef.current, cameraRef.current)
        );
        composerRef.current.addPass(effectPass);

        isInitializedRef.current = true;
        handleResize();
      });
    };

    init();
    window.addEventListener("resize", handleResize);
    frameId.current = requestAnimationFrame(animate);

    return () => {
      cancelAnimationFrame(frameId.current);
      window.removeEventListener("resize", handleResize);
      if (mountRef.current && rendererRef.current) {
        mountRef.current.removeChild(rendererRef.current.domElement);
      }
      isInitializedRef.current = false;
    };
  }, [animate, handleResize]);

  useEffect(() => {
    if (!showNebula && frameId.current) {
      cancelAnimationFrame(frameId.current);
      frameId.current = null;
    } else if (showNebula && !frameId.current) {
      frameId.current = requestAnimationFrame(animate);
    }
  }, [showNebula, animate]);

  const toggleNebula = () => {
    setShowNebula((prev) => !prev);
  };

  return (
    <>
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: -2,
          background:
            "linear-gradient(45deg, #020209 0%, #0d0d2b 50%, #030316 100%)",
        }}
      />
      <div
        ref={mountRef}
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: -1,
          opacity: showNebula ? 1 : 0,
          transition: "opacity 0.5s ease-in-out",
          background: `linear-gradient(135deg, rgb(21 67 156) 0%, rgb(12 215 250) 25%, rgb(4 32 158) 50%, rgb(30 107 222) 75%, rgb(186 1 162) 100%)`,
        }}
      />
      <button
        onClick={toggleNebula}
        style={{
          position: "fixed",
          bottom: "70px",
          right: "20px",
          zIndex: 1000,
          width: "50px",
          height: "50px",
          borderRadius: "50%",
          background: showNebula
            ? "rgba(255, 255, 255, 0.9)"
            : "rgba(255, 255, 255, 0.9)",
          border: "none",
          cursor: "pointer",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          boxShadow: "0 2px 5px rgba(0,0,0,0.3)",
          transition: "all 0.3s ease",
        }}
        aria-label={showNebula ? "Hide Nebula" : "Show Nebula"}
      >
        <NebulaIcon
          iconId={showNebula ? "space-scene" : "star-cluster"}
          color={showNebula ? "#FF0000" : "#8A2BE2"}
        />
      </button>
    </>
  );
};

export default NebulaBackground;
