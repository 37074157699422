import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './NavBar.css';
import logo from 'logo.png';
import { navLinks } from './navConfig';

const NavBar = () => {
  const [activeMenu, setActiveMenu] = useState(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setIsMobileMenuOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleMouseEnter = (index) => {
    if (window.innerWidth > 768) {
      setActiveMenu(index);
    }
  };

  const handleMouseLeave = () => {
    if (window.innerWidth > 768) {
      setActiveMenu(null);
    }
  };

  const handleClick = (index) => {
    setActiveMenu(activeMenu === index ? null : index);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <nav className={`navbar ${isMobileMenuOpen ? 'mobile-open' : ''}`}>
      <Link to="/" className="navbar-logo">
        <img src={logo} alt="Logo" />
      </Link>
      <button className="hamburger-menu" onClick={toggleMobileMenu}>
        <span></span>
        <span></span>
        <span></span>
      </button>
      <div className="navbar-links-container">
        <ul className="navbar-links">
          {navLinks.map((link, index) => (
            <li key={index} 
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
                onClick={() => handleClick(index)}>
              {link.submenu ? (
                <>
                  <span>{link.title}</span>
                  <ul className={`submenu ${activeMenu === index ? 'active' : ''}`}>
                    {link.submenu.map((sublink, subIndex) => (
                      <li key={subIndex}>
                        <Link to={sublink.url} onClick={toggleMobileMenu}>{sublink.title}</Link>
                      </li>
                    ))}
                  </ul>
                </>
              ) : (
                <Link to={link.url} onClick={toggleMobileMenu}>{link.title}</Link>
              )}
            </li>
          ))}
        </ul>
      </div>
    </nav>
  );
};

export default NavBar;