import React, { useState, useEffect, useCallback } from "react";

const NeuralNetwork = () => {
  const [animationProgress, setAnimationProgress] = useState(0);
  const [isAnimating, setIsAnimating] = useState(true);
  const [hoveredNeuron, setHoveredNeuron] = useState(null);

  const layers = [
    { name: "Entrée", neurons: [1, 2], color: "#6366F1" },
    {
      name: "Dense",
      neurons: [0.6, 1.3, 2],
      color: "#8B5CF6",
      weights: [
        [0.1, 0.3, 0.5],
        [0.2, 0.4, 0.6],
      ],
      bias: [0.1, 0.2, 0.3],
    },
    { name: "ReLU", neurons: [0.6, 1.3, 2], color: "#EC4899" },
    {
      name: "Dense",
      neurons: [3.66, 4.93],
      color: "#8B5CF6",
      weights: [
        [0.7, 1],
        [0.8, 1.1],
        [0.9, 1.2],
      ],
      bias: [0.4, 0.5],
    },
  ];

  const aspectRatio = 16 / 10;
  const height = 500;
  const width = height * aspectRatio;

  const layerWidth = width / (layers.length + 1);
  const neuronRadius = 25;

  const animationDuration = 4000;

  const restartAnimation = useCallback(() => {
    setAnimationProgress(0);
    setIsAnimating(true);
  }, []);

  useEffect(() => {
    let timer;
    if (isAnimating) {
      timer = setInterval(() => {
        setAnimationProgress((prev) => {
          if (prev >= 1) {
            clearInterval(timer);
            setIsAnimating(false);
            return 1;
          }
          return prev + 1 / (animationDuration / 50);
        });
      }, 50);
    }
    return () => clearInterval(timer);
  }, [isAnimating, animationDuration]);

  const calculateNeuronFormula = (layerIndex, neuronIndex) => {
    if (layerIndex === 0)
      return `Input ${neuronIndex + 1}: ${layers[0].neurons[neuronIndex]}`;

    const prevLayer = layers[layerIndex - 1];
    const currentLayer = layers[layerIndex];

    if (!currentLayer.weights || !currentLayer.bias) {
      return `${currentLayer.name} ${neuronIndex + 1}: ${currentLayer.neurons[
        neuronIndex
      ].toFixed(2)}`;
    }

    let formula = `${currentLayer.name} ${neuronIndex + 1} = `;
    let terms = [];

    for (let i = 0; i < prevLayer.neurons.length; i++) {
      const weight = currentLayer.weights[i]
        ? currentLayer.weights[i][neuronIndex]
        : 0;
      const prevNeuronValue = prevLayer.neurons[i];
      terms.push(`(${prevNeuronValue.toFixed(2)} * ${weight.toFixed(2)})`);
    }

    formula += terms.join(" + ");
    formula += ` + ${currentLayer.bias[neuronIndex].toFixed(2)}`;

    if (currentLayer.name === "ReLU") {
      formula = `max(0, ${formula})`;
    }

    return formula;
  };

  const Connection = ({ start, end, weight, progress }) => {
    const midX = (start.x + end.x) / 2;
    const midY = (start.y + end.y) / 2;
    return (
      <g>
        <defs>
          <linearGradient
            id={`gradient-${start.x}-${start.y}-${end.x}-${end.y}`}
            x1="0%"
            y1="0%"
            x2="100%"
            y2="0%"
          >
            <stop offset={`${progress * 100}%`} stopColor="#60A5FA" />
            <stop offset={`${progress * 100}%`} stopColor="#E2E8F0" />
          </linearGradient>
        </defs>
        <line
          x1={start.x}
          y1={start.y}
          x2={end.x}
          y2={end.y}
          stroke={`url(#gradient-${start.x}-${start.y}-${end.x}-${end.y})`}
          strokeWidth="2"
        />
        <circle
          cx={midX}
          cy={midY}
          r="10"
          fill={progress > 0 ? "#60A5FA" : "#CBD5E1"}
        />
        <text
          x={midX}
          y={midY}
          textAnchor="middle"
          dominantBaseline="middle"
          fill="#1E293B"
          fontSize="10"
        >
          {weight.toFixed(2)}
        </text>
      </g>
    );
  };

  const Neuron = ({
    x,
    y,
    value,
    color,
    bias,
    isActive,
    showValue,
    layerIndex,
    neuronIndex,
  }) => (
    <g
      onMouseEnter={() => setHoveredNeuron({ layerIndex, neuronIndex })}
      onMouseLeave={() => setHoveredNeuron(null)}
    >
      <circle
        cx={x}
        cy={y}
        r={neuronRadius}
        fill={isActive ? color : "#CBD5E1"}
        onMouseEnter={() => setHoveredNeuron({ layerIndex, neuronIndex })}
        onMouseLeave={() => setHoveredNeuron(null)}
      />
      <circle
        cx={x}
        cy={y}
        r={neuronRadius - 3}
        fill="white"
        fillOpacity="0.3"
      />
      {showValue && (
        <text
          x={x}
          y={y}
          textAnchor="middle"
          dominantBaseline="middle"
          fill="white"
          fontSize="14"
          fontWeight="bold"
        >
          {typeof value === "number" ? value.toFixed(2) : ""}
        </text>
      )}
      {bias !== undefined && (
        <text
          x={x}
          y={y + neuronRadius + 15}
          textAnchor="middle"
          fill="#64748B"
          fontSize="12"
        >
          b: {bias.toFixed(2)}
        </text>
      )}
    </g>
  );

  const Popup = ({ x, y, content }) => (
    <foreignObject x={x - 150} y={y - 100} width="300" height="80">
      <div
        style={{
          border: "1px solid #CBD5E1",
          borderRadius: "4px",
          padding: "8px",
          fontSize: "12px",
          overflowWrap: "break-word",
          boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
        }}
      >
        {content}
      </div>
    </foreignObject>
  );

  const RestartIcon = ({ size = 24 }) => (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.65 6.35C16.2 4.9 14.21 4 12 4C7.58 4 4 7.58 4 12C4 16.42 7.58 20 12 20C15.73 20 18.84 17.45 19.73 14H17.65C16.83 16.33 14.61 18 12 18C8.69 18 6 15.31 6 12C6 8.69 8.69 6 12 6C13.66 6 15.14 6.69 16.22 7.78L13 11H20V4L17.65 6.35Z" fill="currentColor"/>
    </svg>
  );

  const activeLayerIndex = Math.floor(animationProgress * layers.length);
  const layerProgress = (animationProgress * layers.length) % 1;

  return (
    <div 
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
    >
      <svg
        viewBox={`0 0 ${width} ${height}`}
        width={width}
        height={height}
        onMouseLeave={() => setHoveredNeuron(null)}
      >
        {layers.map((layer, layerIndex) => (
          <g key={layerIndex}>
            <text
              x={layerWidth * (layerIndex + 1)}
              y="30"
              textAnchor="middle"
              fill="#334155"
              fontSize="16"
              fontWeight="bold"
            >
              {layer.name}
            </text>
            {layer.neurons.map((neuron, neuronIndex) => {
              const x = layerWidth * (layerIndex + 1);
              const y =
                (height / (layer.neurons.length + 1)) * (neuronIndex + 1);
              const isActive = layerIndex <= activeLayerIndex;
              const showValue =
                layerIndex < activeLayerIndex ||
                (layerIndex === activeLayerIndex && layerProgress === 1);

              return (
                <g key={`${layerIndex}-${neuronIndex}`}>
                  <Neuron
                    x={x}
                    y={y}
                    value={neuron}
                    color={layer.color}
                    bias={layer.bias?.[neuronIndex]}
                    isActive={isActive}
                    showValue={showValue}
                    layerIndex={layerIndex}
                    neuronIndex={neuronIndex}
                  />
                  {layerIndex > 0 &&
                    layers[layerIndex - 1].neurons.map((_, prevNeuronIndex) => {
                      const prevX = layerWidth * layerIndex;
                      const prevY =
                        (height / (layers[layerIndex - 1].neurons.length + 1)) *
                        (prevNeuronIndex + 1);
                      const weight =
                        layer.weights?.[prevNeuronIndex]?.[neuronIndex] ?? 0;
                      const connectionProgress =
                        layerIndex === activeLayerIndex
                          ? layerProgress
                          : layerIndex < activeLayerIndex
                          ? 1
                          : 0;
                      return (
                        <Connection
                          key={`${layerIndex}-${neuronIndex}-${prevNeuronIndex}`}
                          start={{ x: prevX + neuronRadius, y: prevY }}
                          end={{ x: x - neuronRadius, y: y }}
                          weight={weight}
                          progress={connectionProgress}
                        />
                      );
                    })}
                </g>
              );
            })}
          </g>
        ))}
        {hoveredNeuron && (
          <Popup
            x={layerWidth * (hoveredNeuron.layerIndex + 1)}
            y={
              (height / (layers[hoveredNeuron.layerIndex].neurons.length + 1)) *
              (hoveredNeuron.neuronIndex + 1)
            }
            content={calculateNeuronFormula(
              hoveredNeuron.layerIndex,
              hoveredNeuron.neuronIndex
            )}
          />
        )}
        <g
          transform={`translate(${width - 60}, ${height - 60})`}
          onClick={restartAnimation}
          style={{ cursor: 'pointer' }}
        >
          <circle r="10" fill="#3B82F6" />
          <g transform="translate(-5, -5)">
            <RestartIcon size={10} />
          </g>
        </g>
      </svg>
    </div>
  );
};

export default NeuralNetwork;