import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer style={{ 
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '0 2rem',
      backgroundColor: 'rgba(26, 26, 46, 0.9)',
      color: 'white',
      position: 'relative',
      margin: '20px 20px 10px 20px',
      height: '50px',
      borderRadius: '10px',
      zIndex: 1000
    }}>
      <p style={{ margin: 0, fontSize: '0.9rem', color: "white" }}>
        © {new Date().getFullYear()} Tous droits réservés
      </p>
      <Link to="/cgu" style={{ 
        color: 'white', 
        textDecoration: 'none', 
        fontSize: '0.9rem',
      }}>
        CGU
      </Link>
    </footer>
  );
};

export default Footer;