import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Helmet } from "react-helmet";
import NebulaBackground from "./components/NebulaBackground";
import NavBar from "./components/navbar/NavBar";
import AppRouter from "./pages/page";
import "./App.css";
import Footer from "./components/Footer/Footer";

function App() {
  const domain = window.location.hostname;
  const isComDomain = domain === "leafnebula.com";

  return (
    <Router>
      <div className="App">
        <Helmet>
          <html lang={isComDomain ? "en" : "fr"} />
          <title>
            {isComDomain
              ? "Leaf Nebula | My AI Journey and Knowledge"
              : "Leaf Nebula | Mon Parcours et Connaissances en IA"}
          </title>
          <meta
            name="description"
            content={
              isComDomain
                ? "Explore my journey in AI, including personal projects, knowledge, and insights into neural networks and other AI topics."
                : "Découvrez mon parcours en IA, y compris mes projets personnels, mes connaissances et mes réflexions sur les réseaux de neurones et d'autres sujets liés à l'IA."
            }
          />
          <link rel="canonical" href={`https://${domain}`} />
          <link rel="alternate" hreflang="fr" href="https://leafnebula.fr" />
          <link rel="alternate" hreflang="en" href="https://leafnebula.com" />
        </Helmet>
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: -2,
            background:
              "linear-gradient(45deg, #020209 0%, #0d0d2b 50%, #030316 100%)",
          }}
        />
        <NebulaBackground />
        <header>
          <NavBar isComDomain={isComDomain} />
        </header>
        <main style={{ paddingTop: "90px", textAlign: "center" }}>
          <AppRouter isComDomain={isComDomain} />
        </main>
        <Footer isComDomain={isComDomain} />
      </div>
    </Router>
  );
}

export default App;
