import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import Structure from "./components/Structure";
import ForwardPropagation from "./components/ForwardPropagation";
import ErrorCalculation from "./components/ErrorCalculation";
import Backpropagation from "./components/Backpropagation";
import "./NeuralNetworkExplanation.css";
import PageContainer from "components/page-container/pageContainer";

const NeuralNetworkExplanation = () => {
  const [activeSection, setActiveSection] = useState("structure");
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const sections = [
    { id: "structure", title: "Structure du Réseau", component: Structure },
    {
      id: "forward",
      title: "Propagation Avant",
      component: ForwardPropagation,
    },
    { id: "error", title: "Calcul de l'Erreur", component: ErrorCalculation },
    { id: "backprop", title: "Rétropropagation", component: Backpropagation },
  ];

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  const handleSectionChange = (sectionId) => {
    setActiveSection(sectionId);
    setIsMenuOpen(false);
  };

  return (
    <PageContainer>
      <div className="neural-network-explanation">
        <motion.header
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="explanation-header"
        >
          <h1>Réseau de Neurones : Explication Détaillée</h1>
        </motion.header>

        <nav className="section-nav">
          <button className="nav-toggle" onClick={toggleMenu}>
            {sections.find((s) => s.id === activeSection).title}
          </button>
          <div className={`nav-menu ${isMenuOpen ? "open" : ""}`}>
            {sections.map((section) => (
              <motion.button
                key={section.id}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => handleSectionChange(section.id)}
                className={`nav-button ${
                  activeSection === section.id ? "active" : ""
                }`}
              >
                {section.title}
              </motion.button>
            ))}
          </div>
        </nav>

        <AnimatePresence mode="wait">
          <motion.section
            key={activeSection}
            initial={{ opacity: 0, x: 100 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -100 }}
            transition={{ duration: 0.3 }}
            className="content"
          >
            {sections.map(
              (section) =>
                activeSection === section.id && (
                  <section.component key={section.id} />
                )
            )}
          </motion.section>
        </AnimatePresence>
      </div>
    </PageContainer>
  );
};

export default NeuralNetworkExplanation;
